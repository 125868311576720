<template>
  <validation-observer
    ref="observer"
    autocomplete="off"
    tag="v-form"
  >
    <v-card>
      <v-toolbar
        class="cw-business-loan-signees-dialog__toolbar"
        color="transparent"
        flat
      >
        <v-toolbar-title><translate>Add representative</translate></v-toolbar-title>
        <v-spacer/>
        <v-btn
          v-if="data.openFullscreen"
          id="cancel-dialog"
          icon
          @click="
            onDialogClose();
            $eventLogger.clickEvent($event);
          "
        >
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="pt-0 px-6">
        <v-row class="cw-business-loan-signees__owners">
          <v-col cols="12">
            <v-alert
              border="left"
              type="info"
              dense
              text
            >
              <translate>
                Company representatives are those who own or otherwise control the company.
              </translate>
            </v-alert>
          </v-col>

          <v-col cols="12" sm="6">
            <cw-text-field
              id="owner-name"
              v-model="owner.name"
              :extend-rules="false"
              :rules="`required|error:${hasError('name')}`"
              :hint="ownerNameHint"
              :label="ownerNameLabel"
              type="text"
              anonymize
              prevent-set-data
              @focus="removeApiError(`owners.${data.index}.name`)"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <cw-text-field
              id="owner-ssn"
              v-model="owner.ssn"
              :extend-rules="false"
              :rules="`required|ssn|error:${hasError('ssn')}|unique:${data.owners.map(o => o.ssn)}`"
              :hint="ownerSsnHint"
              :label="ownerSsnLabel"
              type="text"
              anonymize
              prevent-set-data
              @focus="removeApiError(`owners.${data.index}.ssn`)"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <cw-text-field
              id="owner-percentage"
              v-model="owner.percentage"
              :extend-rules="false"
              :rules="
                `required|max_value:${data.max}|notNegativeNumber|error:${hasError('percentage')}`
              "
              :hint="ownerPercentageHint"
              :label="ownerPercentageLabel"
              suffix="%"
              type="number"
              prevent-set-data
              @focus="removeApiError(`owners.${data.index}.percentage`)"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer/>
        <v-btn
          v-if="!data.openFullscreen"
          id="cancel-dialog"
          text
          @click="
            onDialogClose();
            $eventLogger.clickEvent($event);
          "
        >
          <translate>Cancel</translate>
        </v-btn>
        <v-btn
          id="add-owner"
          :disabled="$wait.waiting('signees__add-item')"
          :loading="$wait.waiting('signees__add-item')"
          color="primary"
          text
          @click="
            onAddItem();
            $eventLogger.clickEvent($event);
          "
        >
          <translate>Add</translate>
        </v-btn>
      </v-card-actions>
    </v-card>
  </validation-observer>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { ValidationObserver } from 'vee-validate';

export default {
  name: 'CwBusinessLoanSigneesDialogOwnerFields',

  components: {
    'cw-text-field': () => import('@shared/components/inputs/CwTextField'),
    'validation-observer': ValidationObserver,
  },

  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },

  data: () => ({
    owner: {
      name: null,
      ssn: null,
      percentage: null,
    },
  }),

  computed: {
    ...mapState({
      apiErrors: state => state.application.apiErrors,
    }),

    /**
     * Gettext translations
     */
    ownerNameHint: vm => vm.$gettext('Name of the applicant'),
    ownerNameLabel: vm => vm.$gettext('Name'),
    ownerSsnHint: vm => vm.$gettext('Identity number in the form of ddmmyy-xxxx'),
    ownerSsnLabel: vm => vm.$gettext('Identity number'),
    ownerPercentageHint: vm => vm.$gettext('Shareholding of the company'),
    ownerPercentageLabel: vm => vm.$gettext('Shareholding'),
  },

  methods: {
    ...mapActions({
      removeApiError: 'application/removeApiError',
    }),

    hasError(prop) {
      const apiError = this.apiErrors.find(error => error.startsWith('owners.'));
      if (!apiError) return false;

      return apiError.split('.').pop() === prop;
    },

    async onAddItem() {
      const valid = await this.$refs.observer.validate();

      if (!valid) return;

      const { $bus, owner } = this;

      $bus.$emit('SIGNEES_ITEM_ADD', ({
        type: 'owner',
        data: owner,
      }));
    },

    onDialogClose() {
      this.$bus.$emit('SIGNEES_DIALOG_CLOSE');
    },
  },
};
</script>
